<template>
  <div>
    <v-row class="pt-10">
      <v-col>
        <h3>Credit Notes</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-simple-table fixed-header height="300">
          <thead>
            <th class="text-left">Status</th>
            <th class="text-left">Number</th>
            <th class="text-left">Date</th>
            <th class="text-left">Customer</th>
            <th class="text-left">Site</th>
            <th class="text-left">Value</th>
            <th class="text-left">Sales Person</th>
            <th>&nbsp;</th>
          </thead>
          <tbody>
            <tr v-for="(note, index) in creditNotes" :key="`i_${index}`">
              <td>{{ note.statusLetter }}</td>
              <td>{{ note.jobNumber }}</td>
              <td>{{ note.date | tinyDate }}</td>
              <td>
                <router-link :to="`/customers/view/${note.customerId}`">
                  {{ note.customer }}
                </router-link>
              </td>
              <td>{{ note.site }}</td>
              <td>{{ note.value | priceInPounds | currency }}</td>
              <td>{{ note.salesPerson }}</td>
              <td class="pr-0">
                <router-link :to="`/invoices/view-credit-note/${note.id}`">
                  <v-btn x-small>View</v-btn>
                </router-link>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'SearchResultCreditNotes',
  props: [
    'creditNotes',
  ],
};
</script>
