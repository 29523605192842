<template>
  <div>
    <v-row class="pt-10">
      <v-col>
        <h3>Invoices</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-simple-table fixed-header height="300">
          <thead>
            <th class="text-left">Status</th>
            <th class="text-left">Number</th>
            <th class="text-left">Date</th>
            <th class="text-left">Customer</th>
            <th class="text-left">Site</th>
            <th class="text-left">Value</th>
            <th class="text-left">Sales Person</th>
            <th>&nbsp;</th>
          </thead>
          <tbody>
            <tr v-for="(invoice, index) in invoices" :key="`i_${index}`">
              <td>{{ invoice.statusLetter }}</td>
              <td>{{ invoice.jobNumber }}</td>
              <td>{{ invoice.date | tinyDate }}</td>
              <td>
                <router-link :to="`/customers/view/${invoice.customerId}`">
                  {{ invoice.customer }}
                </router-link>
              </td>
              <td>{{ invoice.site }}</td>
              <td>{{ invoice.value | priceInPounds | currency }}</td>
              <td>{{ invoice.salesPerson }}</td>
              <td class="pr-0">
                <router-link
                    v-if="userPermissions.includes(4)"
                    custom
                    v-slot="{ navigate }"
                    :to="`/invoices/view-draft/${invoice.id}`">
                    <v-btn
                      title="View"
                      x-small
                      @click="navigate"
                      @keypress.enter="navigate"
                      role="link">
                        View
                    </v-btn>
                  </router-link>
                  <router-link
                    v-else
                    custom
                    v-slot="{ navigate }"
                    :to="`/invoices/print-view/${invoice.id}`">
                    <v-btn
                      title="View"
                      x-small
                      @click="navigate"
                      @keypress.enter="navigate"
                      role="link">
                        View
                    </v-btn>
                  </router-link>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'SearchResultInvoices',
  props: [
    'invoices',
  ],
  computed: {
    userPermissions() {
      return this.$store.state.userPermissions;
    },
  },
};
</script>
