<template>
  <div>
		<v-row>
      <v-col>
        <h3>Quotations</h3>
      </v-col>
    </v-row>
		<v-row>
      <v-col>
        <v-simple-table fixed-header height="300">
          <thead>
            <th class="text-left">Status</th>
            <th class="text-left">Date</th>
            <th class="text-left">Number</th>
            <th class="text-left">Customer</th>
            <th class="text-left">Site</th>
            <th class="text-left">Value</th>
            <th class="text-left">Sales Person</th>
            <th>&nbsp;</th>
          </thead>
          <tbody>
            <tr v-for="(quotation, index) in quotations" :key="index">
              <td>{{ quotation.statusLetter }}</td>
              <td>{{ quotation.date | tinyDate }}</td>
              <td>{{ quotation.jobNumber }}</td>
              <td>
                <router-link :to="`/customers/view/${quotation.customerId}`">
                  {{ quotation.customer }}
                </router-link>
              </td>
              <td>{{ quotation.site }}</td>
              <td>{{ quotation.value | priceInPounds | currency }}</td>
              <td>{{ quotation.salesPerson }}</td>
              <td>
                <router-link :to="`quotations/view/${quotation.id}`">
                  <v-btn x-small>View</v-btn>
                </router-link>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
	</div>
</template>

<script>
export default {
  name: 'SearchResultQuotations',
	props: [
		'quotations',
	],
};
</script>
