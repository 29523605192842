<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12">
        <h1>
          Dashboard
          <span class="float-right">
            <a class="text-orange" href="https://help.riggott-ims.co.uk/" target="_blank">
              <v-icon
                large
                color="orange">
                mdi-lifebuoy
              </v-icon>
              Help
            </a>
          </span>
        </h1>
      </v-col>
    </v-row>
    <Search />
    <v-row>
      <v-col class="col-12 col-md-4">
        <Sales />
      </v-col>
      <v-col class="col-12 col-md-8">
        <Directors v-if="userid === 4 ||userid === 107 || userid === 108 || userid === 111 || userid === 112" />
      </v-col>
    </v-row>
    <span
      @click="updateInvoiceLines"
      style="margin-top:1500px; display:inline-block">
      ui {{ linesUpdated }}
    </span>
  </v-container>
</template>

<script>
import Directors from '@/components/Dashboard/Directors.vue';
import Sales from '@/components/Dashboard/Sales.vue';
import Search from '@/components/Dashboard/Search.vue';
import axios from '../axios';

export default {
  name: 'Start',
  data() {
    return {
      linesUpdated: 0,
    };
  },
  components: {
    Directors,
    Sales,
    Search,
  },
  computed: {
    userid() {
      return this.$store.state.userid;
    },
    token() {
      return this.$store.state.token;
    },
  },
  methods: {
    updateInvoiceLines() {
      if (this.linesUpdated < 1000) {
        axios.get(`/invoices/updateInvoiceLines.json?token=${this.token}`)
          .then(() => {
            this.linesUpdated += 1;
            this.updateInvoiceLinesTwo();
          });
        }
    },
    updateInvoiceLinesTwo() {
      axios.get(`/invoices/updateInvoiceLines.json?token=${this.token}`)
        .then(() => {
          this.linesUpdated += 1;
          this.updateInvoiceLines();
        });
    },
  },
};
</script>
