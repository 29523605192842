<template>
  <div>
    <v-row class="pt-10">
      <v-col>
        <h3>Applications</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-simple-table fixed-header height="300">
          <thead>
            <th class="text-left">Status</th>
            <th class="text-left">Number</th>
            <th class="text-left">Customer</th>
            <th class="text-left">Site</th>
            <th class="text-left">Value</th>
            <th class="text-left">Sales Person</th>
            <th>&nbsp;</th>
          </thead>
          <tbody>
            <tr v-for="(application, index) in applications" :key="`i_${index}`">
              <td>{{ application.statusLetter }}</td>
              <td>{{ application.jobNumber }}</td>
              <td>
                <router-link :to="`/customers/view/${application.customerId}`">
                  {{ application.customer }}
                </router-link>
              </td>
              <td>{{ application.site }}</td>
              <td>{{ application.value | priceInPounds | currency }}</td>
              <td>{{ application.salesPerson }}</td>
              <td class="pr-0">
                <router-link :to="`/invoices/view-draft/${application.id}`">
                  <v-btn x-small>View</v-btn>
                </router-link>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'SearchResultApplications',
  props: [
    'applications',
  ],
};
</script>
