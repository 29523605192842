<template>
  <div>
    <v-form @submit.prevent="getAll">
      <v-row dense class="pt-8">
        <v-col class="col">
          <label>Job No</label>
          <v-text-field v-model="jobNumber"></v-text-field>
        </v-col>
        <v-col class="col">
          <label>Customer</label>
          <v-text-field v-model="customer"></v-text-field>
        </v-col>
        <v-col class="col">
          <label>Sage Ref</label>
          <v-text-field v-model="sageRef"></v-text-field>
        </v-col>
        <v-col class="col">
          <label>Sales' initials</label>
          <v-text-field v-model="salesPerson"></v-text-field>
        </v-col>
        <v-col class="col">
          <label>Job Status</label>
          <v-select v-model="jobStatus" :items="jobStatuses"></v-select>
        </v-col>
        <v-col class="col">
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="date"
            persistent
            width="350px"
          >
            <template v-slot:activator="{ on, attrs }">
              <label class="pl-8">Date</label>
              <v-text-field
                v-model="date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              range>
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="modal = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialog.save(date)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col>
            <label>Site search</label>
            <v-text-field
              placeholder="Address"
              v-model="jobSite"></v-text-field>
          </v-col>
        <v-col class="col-3 pt-10">
          <v-btn type="sumbit">Search All</v-btn>
          &nbsp;
          <v-btn @click="clearSearch">Clear</v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-tabs v-model="tab">
      <v-tab v-if="quotations[0]">Quotations</v-tab>
      <v-tab v-if="jobs[0]">Jobs</v-tab>
      <v-tab v-if="invoices[0]">Invoices</v-tab>
      <v-tab v-if="applications[0]">Applications</v-tab>
      <v-tab v-if="creditNotes[0]">Credit Notes</v-tab>
      <v-tab v-if="drs[0]">DRS</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item v-if="quotations[0]">
        <Quotations :quotations="quotations" />
      </v-tab-item>
      <v-tab-item v-if="jobs[0]">
        <Jobs :jobs="jobs" />
      </v-tab-item>
      <v-tab-item v-if="invoices[0]">
        <Invoices :invoices="invoices" />
      </v-tab-item>
      <v-tab-item v-if="applications[0]">
        <Applications :applications="applications" />
      </v-tab-item>
      <v-tab-item v-if="creditNotes[0]">
        <CreditNotes :creditNotes="creditNotes" />
      </v-tab-item>
      <v-tab-item v-if="drs[0]">
        <Drs :drs="drs" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import Applications from '@/components/Dashboard/SearchResults/Applications.vue';
import CreditNotes from '@/components/Dashboard/SearchResults/CreditNotes.vue';
import Invoices from '@/components/Dashboard/SearchResults/Invoices.vue';
import Quotations from '@/components/Dashboard/SearchResults/Quotations.vue';
import Drs from '@/components/Dashboard/SearchResults/Drs.vue';
import Jobs from '@/components/Dashboard/SearchResults/Jobs.vue';
import axios from '../../axios';

export default {
  name: 'Search',
  components: {
    Applications,
    CreditNotes,
    Invoices,
    Quotations,
    Drs,
    Jobs,
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      tab: null,
      quotations: [],
      invoices: [],
      applications: [],
      creditNotes: [],
      drs: [],
      jobs: [],
      page: 1,
      length: 0,
      totalVisible: 9,
      date: [],
      dateDialogue: false,
      modal: false,
      customer: '',
      salesPerson: '',
      sageRef: '',
      jobNumber: '',
      jobSite: '',
      jobStatus: 0,
      jobStatuses: [
        { value: 1, text: 'Unconverted' },
        { value: 2, text: 'Converted' },
        { value: 3, text: 'Planned' },
        { value: 4, text: 'Awaiting Invoice' },
        { value: 5, text: 'Invoiced' },
        { value: 6, text: 'Remedial' },
        { value: 7, text: 'Lost' },
      ],
    };
  },
  methods: {
    clearSearch() {
      this.applications = [];
      this.customer = '';
      this.date = [];
      this.drs = [];
      this.invoices = [];
      this.items = [];
      this.jobNumber = '';
      this.jobs = [];
      this.jobSite = '';
      this.jobStatus = 0;
      this.length = 0,
      this.page = 1,
      this.quotations = [];
      this.sageRef = '';
      this.salesPerson = '';
      this.setDashboardSearchParams();
    },
    getAll() {
      const postData = {};
      postData.customer = this.customer;
      postData.date = this.date;
      postData.jobNumber = this.jobNumber;
      postData.jobSite = this.jobSite;
      postData.jobStatus = this.jobStatus;
      postData.salesPerson = this.salesPerson;
      postData.sageRef = this.sageRef;
      this.setDashboardSearchParams();
      axios.post(`/utilityTools/getAll/${this.page}.json?token=${this.token}`, postData)
        .then((response) => {
          this.quotations = response.data.items.quotes;
          this.applications = response.data.items.applications;
          this.creditNotes = response.data.items.creditNotes  ;
          this.invoices = response.data.items.invoices;
          this.jobs = response.data.items.jobs;
          this.drs = response.data.items.drs;
          this.length = response.data.items.quotes.length;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setDashboardSearchParams() {
      const params = {};
      params.customer = this.customer;
      params.date = this.date;
      params.jobNumber = this.jobNumber;
      params.jobSite = this.jobSite;
      params.jobStatus = this.jobStatus;
      params.sageRef = this.sageRef;
      params.salesPerson = this.salesPerson;
      this.$store.commit('setDashboardSearchParams', params);
    },
    getSearchParams() {
      this.customer = this.$store.state.dashboardSearchParams.customer;
      this.date = this.$store.state.dashboardSearchParams.date;
      this.jobNumber = this.$store.state.dashboardSearchParams.jobNumber;
      this.jobSite = this.$store.state.dashboardSearchParams.jobSite;
      this.jobStatus = this.$store.state.dashboardSearchParams.jobStatus;
      this.sageRef = this.$store.state.dashboardSearchParams.sageRef;
      this.salesPerson = this.$store.state.dashboardSearchParams.salesPerson;
      if (this.jobNumber || this.customer || this.salesPerson || this.sageRef || this.date || this.jobStatus || this.jobSite) {
        this.getAll();
      }
    },
  },
  mounted() {
    this.getSearchParams();
  },
};
</script>
