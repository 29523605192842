<template>
  <div>
    <v-row class="pt-10">
      <v-col>
        <h3>Jobs</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-simple-table fixed-header height="300">
          <thead>
            <th class="text-left">Number</th>
            <th class="text-left">Customer</th>
            <th class="text-left">Site</th>
            <th class="text-left">Value</th>
            <th class="text-left">Sales Person</th>
            <th>&nbsp;</th>
          </thead>
          <tbody>
            <tr v-for="(job, index) in jobs" :key="index">
              <td>{{ job.jobNumber }}</td>
              <td>
                <router-link :to="`/customers/view/${job.customerId}`">
                  {{ job.customer }}
                </router-link>
              </td>
              <td>{{ job.site }}</td>
              <td>{{ job.value | priceInPounds | currency }}</td>
              <td>{{ job.salesPerson }}</td>
              <td>
                <router-link :to="`live-jobs/overview/${job.id}`">
                  <v-btn x-small>View</v-btn>
                </router-link>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'SearchResultJobs',
  props: [
    'jobs',
  ],
};
</script>
