<template>
  <div>
    <v-row>
      <v-col>
        <h4>My quotations to date this month</h4>
        <p>{{ salesFigures.name }}: {{ salesFigures.total | priceInPounds | currency }}</p>
        <h4>My conversions to date this month</h4>
        <p>{{ conversionFigures.name }}: {{ conversionFigures.total | priceInPounds | currency }}</p>
        <h4>My invoiced jobs to date this month</h4>
        <p>{{ invoiceFigures.name }}: {{ invoiceFigures.total | priceInPounds | currency }}</p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'Sales',
  data() {
    return {
      conversionFigures: [],
      salesFigures: [],
      invoiceFigures: [],
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
    userid() {
      return this.$store.state.userid;
    }
  },
  methods: {
    getConversionFigures() {
      axios.get(`/quotations/getDashboardConversionFigures.json?token=${this.token}&user=${this.userid}`)
        .then((response) => {
          this.conversionFigures = response.data.conversionFigures;
        });
    },
    getSalesFigures() {
      axios.get(`/quotations/getDashboardSalesFigures.json?token=${this.token}&user=${this.userid}`)
        .then((response) => {
          this.salesFigures = response.data.salesFigures;
        });
    },
    getInvoiceFigures() {
      axios.get(`/quotations/getDashboardInvoicedFigures.json?token=${this.token}&user=${this.userid}`)
        .then((response) => {
          this.invoiceFigures = response.data.salesFigures;
        });
    },
  },
  mounted() {
    this.getConversionFigures();
    this.getSalesFigures();
    this.getInvoiceFigures();
  },
};
</script>
